import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Inv No.', 'invoiceRef', true, 'desc', 'left'),
  new TableColumn('Debtor Name', 'debtorName', true, 'desc', 'left'),
  new TableColumn('Rec\'d Amt', 'amountPaid', true, 'asc', 'right'),
  new TableColumn('Rec\'d Date', 'payDate', true, 'desc', 'left'),
  new TableColumn('Reference', 'chequeNo', true, 'asc', 'left'),
  new TableColumn('Bank', 'bankAccount', true, 'asc', 'left'),
  new TableColumn('Factored', 'factoring', true, 'desc', 'centered'),
  new TableColumn('Gst Reported', 'gstAmount', true, 'desc', 'right'),
  new TableColumn('Report Dated', 'gstPaid', true, 'desc', 'left'),
  new TableColumn('', 'view', true, 'desc', 'left')
]

export { Columns }
