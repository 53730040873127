<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="showDelete"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <debtor-receipt-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.invoicePaymentId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td><a @click="gotoV1(entity.paymentType, entity.invoicePaymentId, entity.invoiceId, entity.adjustmentInvoiceId, entity.invoiceRef)">
                  {{ entity.invoiceRef }}
                </a>
                </td>
                <td>{{ entity.debtorName }}</td>
                <td class="has-text-right">{{ entity.amountPaid | formatNumber($userInfo.locale) }}</td>
                <td>{{ $filters.formatDateTimezone(entity.payDate, $userInfo.locale) }}</td>
                <td>{{ entity.chequeNo }}</td>
                <td>{{ entity.bankAccount }}</td>
                <td class="has-text-centered">
                  <span class="icon"
                    :class="[ entity.factoring ? 'has-text-success' : 'has-text-danger' ]">
                    <i class="mdi mdi-24px"
                      :class="[ entity.factoring ? 'mdi-check' : '' ]" />
                  </span>
                </td>
                <!-- <td class="has-text-right">{{ entity.gstAmount | formatNumber($userInfo.locale) }}</td> -->
                <td class="has-text-right">{{ entity.gstPaid == null ? '' : entity.gstAmount | formatNumber($userInfo.locale) }}</td>
                <td>{{ $filters.formatDateTimezone(entity.gstPaid, $userInfo.locale) }}</td>
                <td class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip is-tooltip-topright"
                    data-tooltip="Click to view"
                    @click="gotoV1(entity.paymentType, entity.invoicePaymentId, entity.invoiceId, entity.adjustmentInvoiceId, entity.invoiceRef)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-file-document mdi-24px" />
                    </span>
                  </a>
                  <a class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                    v-if="checkInvoice(entity)"
                    data-tooltip="Click to delete"
                    @click="deleteButton(entity)">
                    <span class="icon is-medium">
                      <i class="mdi mdi-delete mdi-24px" />
                    </span>
                  </a>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
    <confirm-modal :active.sync="isConfirmModalActive"
      @ok="deleteEntity(true)"
      @cancel="deleteEntity(false)"
      :ok-text="'Yes'"
      :cancel-text="'No'">
      <p slot="text-title">Delete Debtor Receipt List</p>
      <p slot="text-content">
        <span class="has-text-primary has-text-weight-bold">{{ selectedInvoiceRef }}</span> will be deleted. Continue?
      </p>
    </confirm-modal>
    <advance-receipt-modal v-if="isLoadAdvPaymentModalActive"
      :active.sync="isLoadAdvPaymentModalActive"
      :show-icon="false"
      @close="closeModal()">
      <p slot="text-title">Advance Receipt Entry</p>
    </advance-receipt-modal>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import DebtorReceiptListSideMenu from './DebtorReceiptListSideMenu'
import DebtorReceiptService from './DebtorReceiptService'
import { AppHeaderButtonTypes } from '@/enums'
import Guid from '@/components/Guid'
import BulmaTable from '@/components/BulmaTable'
import { DateTimeFiltersMixin } from '@/components/mixins/filters'
import { ConfirmModal } from '@/components/BulmaModal'
import { Columns } from './columns'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'
import _debounce from 'lodash.debounce'
import _cloneDeep from 'lodash/cloneDeep'
import store from '@/store'
import AdvanceReceiptModal from '../advancereceipt/AdvanceReceiptModal'

export default {
  name: 'DebtorsReceiptsListView',
  components: {
    AppDetailHeader,
    [DebtorReceiptListSideMenu.name]: DebtorReceiptListSideMenu,
    BulmaTable,
    ConfirmModal,
    AdvanceReceiptModal
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, StoreMixin],
  data() {
    return {
      entitySummaries: [],
      selectedEntity: null,
      totalRows: 0,
      accessRoutes: [],
      filter: {
        invoiceNo: '',
        debtorName: '',
        dateFrom: '',
        dateTo: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      toggle: true,
      showDelete: false,
      selectedRow: null,
      selectedInvoiceRef: null,
      isConfirmModalActive: false,
      isTableLoading: false,
      isLoadAdvPaymentModalActive: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    }
  },
  watch: {
    selectedEntity: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.selectedEntity))
        }
      }, 500),
      deep: true
    }
  },
  created() {
    this.getEntitySummaries()
    this.getAccessRoutes()
  },
  mounted() {},
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.invoiceNo = ''
      this.filter.debtorName = ''
      this.filter.dateFrom = ''
      this.filter.dateTo = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await DebtorReceiptService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
    },
    async getAccessRoutes() {
      this.accessRoutes = await DebtorReceiptService.getAccessRoutes()
    },
    async gotoV1(paymentType, invoicePaymentId, invoiceId, adjustmentInvoiceId, invoiceRef) {
      let debtorAdjustmentRoute = this.accessRoutes.records.filter((obj) => {
        return obj.code === 'A084'
      })
      let receiptEntryRoute = this.accessRoutes.records.filter((obj) => {
        return obj.code === 'A013'
      })
      switch (true) {
        //enterreceipt V2
        case paymentType === 1 && receiptEntryRoute[0].version.toUpperCase() === '2':
          this.setReceiptReadOnlyView(true)
          await store.dispatch('receiptpayment/editStoreItem', invoicePaymentId)
          this.setReceiptPaymentReturnRoute(this.$route)
          break

        //enterreceipt V1
        case paymentType === 1 && receiptEntryRoute[0].version.toUpperCase() === 'DEFAULT':
          window.open(`${process.env.VUE_APP_ROOT_URI}/modules/ReceiptEntryView/EnterReceipt.aspx?rqid=${invoicePaymentId}`, '_self')
          break

        //Advance v2
        case paymentType === 2 && receiptEntryRoute[0].version.toUpperCase() === '2':
          this.setAdvanceReadOnlyView(true)
          await store.dispatch('advancereceipt/getStoreItem', invoicePaymentId)
          this.$nextTick(() => {
            this.isLoadAdvPaymentModalActive = true
          })
          break

        //Advance V1
        case paymentType === 2 && receiptEntryRoute[0].version.toUpperCase() === 'DEFAULT':
          window.open(`${process.env.VUE_APP_ROOT_URI}/modules/ReceiptEntryView/ReceiptEntry.aspx?Qtid=${invoiceId}`, '_self')
          break

        //Debtor Adjustment V2
        case paymentType === 3 && debtorAdjustmentRoute[0].version.toUpperCase() === '2':
          await store.dispatch('debtoradjustments/editStoreItem', invoiceId)
          this.setAdjustmentReadOnlyView(true)
          this.setDebtorAdjustmentsReturnRoute(this.$route)
          break

        //Debtor Adjustment V1
        case paymentType === 3 && debtorAdjustmentRoute[0].version.toUpperCase() === 'DEFAULT':
          let qid = invoiceRef.includes('AJ') ? Guid.createShortGuid(invoiceId) : Guid.createShortGuid(adjustmentInvoiceId)
          let guid = invoiceRef.includes('AJ') ? invoiceId : adjustmentInvoiceId
          window.open(`${process.env.VUE_APP_ROOT_URI}/Modules/DebtorView/DebtorAdjustmentDetails.aspx?type=debtorlist&qiid=${qid}&guid=${guid}`, '_self')
          break

        //Factor v2
        case paymentType === 4 && receiptEntryRoute[0].version.toUpperCase() === '2':
          await store.dispatch('factorinvoice/editStoreItem', adjustmentInvoiceId)
          this.setFactorInvoiceReturnRoute(this.$route)
          break

        //Factor v1
        case paymentType === 4 && receiptEntryRoute[0].version.toUpperCase() === 'DEFAULT':
          window.open(
            `${process.env.VUE_APP_ROOT_URI}/modules/ReceiptEntryView/FactorDetails.aspx?RPId=${adjustmentInvoiceId}&RedirectUrl=/uniq/v2/debtorsreceipts`,
            '_self'
          )
          break
      }
    },
    async deleteButton(entity) {
      await this.getStoreItem(entity)
      this.selectedEntity = _cloneDeep(entity)
      this.selectedInvoiceRef = entity.invoiceRef
      this.isConfirmModalActive = true
    },
    async deleteEntity(confirmDelete) {
      this.isConfirmModalActive = false
      if (confirmDelete) {
        this.selectedEntity.deleted = true
        await this.saveSnapshot(_cloneDeep(this.selectedEntity))
        this.selectedEntity.deepDiff = JSON.stringify(this.snapshotDiff)
        await DebtorReceiptService.putEntity(this.selectedEntity.invoicePaymentId, _cloneDeep(this.selectedEntity))
        this.getEntitySummaries()
      }
    },
    checkInvoice(entity) {
      return (entity.chequeNo !== 'Adjust' && entity.factoring !== true && entity.gstPaid === null) ||
        (entity.chequeNo !== 'Adjust' && entity.gstPaid === null && entity.factoring === true && this.$userInfo.isSupportUser)
        ? true
        : false
    },
    print() {
      let params = {
        FromDate: this.filter.dateFrom === '' ? '1900-01-01T14:00:00Z' : this.filter.dateFrom,
        ToDate: this.filter.dateTo === '' ? '3000-01-01T14:00:00Z' : this.filter.dateTo,
        InvoiceNo: this.filter.invoiceNo,
        DebtorName: this.filter.debtorName,
        CompanyID: this.$userInfo.companyId,
        SortColumn: this.filter.sortColumn.toUpperCase(),
        SortDirection: this.filter.sortOrder,
        PageIndex: 1,
        PageSize: this.totalRows,
        timezoneoffset: this.$filters.getSiteTimezoneOffset()
      }

      const emailer = new Emailer()
      emailer.subject = 'Debtors Receipt List'
      emailer.reportName = 'rptReceiptListV2'
      this.addEmailer(emailer)

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    closeModal() {
      this.isLoadAdvPaymentModalActive = false
    }
  }
}
</script>